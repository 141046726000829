@use '@shared/util-styling/mixins';

.profile-hero-section {
  $_gradient-start: var(--mdrt-color-backgrounds-bg-primary-fill-01);
  $_gradient-end: var(--mdrt-color-backgrounds-bg-primary-fill-missing);

  background: linear-gradient($_gradient-start, $_gradient-end);

  &__back-button {
    margin-top: var(--spacing-16);
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: var(--spacing-24);
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-24);
  }

  &__membership-info-container {
    background-color: var(--mdrt-color-backgrounds-bg-primary-fill-03);
    padding: var(--spacing-32) 0;
  }

  &__membership-info-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__membership-info-item {
    padding: var(--spacing-24) 0;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      padding-bottom: 0;
    }

    & + & {
      border-top: var(--mdrt-border-thickness-01) solid var(--mdrt-color-text-on-dark-tertiary);
    }
  }

  &__membership-info-item-label {
    color: var(--mdrt-color-text-on-dark-tertiary);
    font: var(--mdrt-title-3);
    text-transform: uppercase;
  }

  &__membership-info-item-value {
    color: var(--mdrt-color-text-on-dark-primary);
    font: var(--mdrt-headline-3);
    margin-bottom: var(--spacing-8);
  }

  &__contact-block {
    background: var(--mdrt-color-backgrounds-bg-surface);
    margin: var(--spacing-24) calc(var(--container-spacing-inline) * -1) 0;
    padding: var(--spacing-24);
  }

  &__contact-block-title {
    color: var(--mdrt-color-text-on-light-primary);
    font: var(--mdrt-headline-4);
    margin-bottom: var(--spacing-24);
  }

  &__contact-block-item {
    align-items: center;
    color: var(--mdrt-color-text-on-light-primary);
    display: flex;
    font: var(--mdrt-title-3);
    gap: var(--spacing-16);
    padding: var(--spacing-12) 0;

    > * {
      @include mixins.ellipsis;
    }

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      padding-bottom: 0;
    }

    a {
      color: var(--mdrt-color-text-on-light-primary);
    }
  }

  &__contact-block-item-icon {
    background-color: var(--mdrt-color-backgrounds-bg-primary-fill-02);
    border-radius: 50%;
    color: var(--mdrt-color-text-on-dark-primary);
    flex-shrink: 0;
    height: 44px;
    padding: 8px;
    width: 44px;
  }

  @include mixins.breakpoint('medium') {
    min-height: 450px;
    padding-bottom: 80px;

    &__content-wrapper {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      margin-top: var(--spacing-40);
    }

    &__content {
      align-items: center;
      flex-direction: row;
      margin-bottom: 0;
      padding-top: 0;
    }

    &__membership-info-items {
      flex-direction: row;
      gap: var(--spacing-8);
    }

    &__membership-info-item {
      padding: 0 var(--spacing-32);

      & + & {
        border-left: var(--mdrt-border-thickness-01) solid var(--mdrt-color-text-on-dark-tertiary);
        border-top: 0;
      }
    }

    &__membership-info-item-label {
      text-align: center;
    }

    &__membership-info-item-value {
      text-align: center;
    }

    &__contact-block {
      border-radius: var(--mdrt-card-radius-large);
      margin: 0;
      max-width: 400px;
    }

    &__contact-block-title {
      display: none;
    }

    &__contact-block-item {
      padding: var(--spacing-16) 0;

      & + & {
        border-top: var(--mdrt-border-thickness-01) solid var(--mdrt-color-borders-dark-01);
      }
    }

    &__contact-block-item-icon {
      padding: 10px;
    }
  }
}
